<template>
  <div class="body">
    <transition name="el-zoom-in-center" appear>
      <div class="homePage">
        <!-- 轮播大图 -->
        <div class="ziHead">
          <el-carousel  height="570px">
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
              <a href="/" target="_blank">
                <img class="swiper_img" :src="item.fileName" style="width: 100% ; height: 100% ;" />
              </a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 新闻主体 -->
        <div class="news_box">
          <div class="layout">
            <!-- 头条新闻 -->
            <div class="news_top">
              <img src="../assets/image/news.c94bfcc.png" alt="" style="margin-right: 30px;"> 
              <div class="block" style="width: 100% !important;">
                <el-carousel indicator-position="outside" arrow="never" height="143px">
                  <el-carousel-item v-for="(item, index) in newsTextList" :key="index">
                    <a v-if="item.url && item.url !== ''" :href="item.url">
                      <div class="con">
                        <p>{{ item.title }}</p>
                        <!-- <div class="conDesc">{{ item.artDesc | ellipsis }}</div> -->
                        <div class="conDesc" style="text-align:left;">{{ item.artDesc }}</div>
                      </div>
                    </a>
                    <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                      <div class="con">
                        <p>{{ item.title }}</p>
                        <!-- <div class="conDesc">{{ item.artDesc | ellipsis }}</div> -->
                        <div class="conDesc" style="text-align:left;">{{ item.artDesc }}</div>
                      </div>
                    </a>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <!-- 新闻中心 -->
            <div class="news_list">
              <div class="news-title">
                <div>新闻中心</div> 
                <img src="../assets/image/news-title.24c8cbd.png" alt="">
              </div> 
              <!-- 新闻中心轮播图 -->
              <div class="img_box">
                <div class="image-w">
                  <el-carousel indicator-position="none" arrow="always" height="395px">
                    <el-carousel-item v-for="(item, index) in newsFlashList" :key="index">
                      <a v-if="item.url && item.url !== ''" :href="item.url">
                        <img class="swiper_img" :src="item.thumb1" style="width: 100% ; height: 100% ;" />
                      </a>
                      <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                        <img class="swiper_img" :src="item.thumb1" style="width: 100% ; height: 100% ;" />
                      </a>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
              <div class="list">
                <div class="Tit" id="NewsT">
                  <div
                    class="NewsTInner"
                    :class="{ active: catalogActive == index }"
                    v-for="(item, index) in catalogList"
                    :key="index"
                    @mouseenter="mouseenterCatalog(index)"
                  >
                  <a :href="item.url" >
                    {{ item.title }}
                  </a>
                  </div>
                  <!-- <div class="NewsTInner2"><a href="/list/01800001">更多</a></div> -->
                </div>

                <div class="ItemList" id="NewsItem">
                  <div class="NewsItem">
                    <!-- 公司新闻 -->
                    <ul class="comUl" v-show="catalogActive == 0">
                      <li v-for="(item, index) in newCatalogList01" :key="index">
                        <a v-if="item.url && item.url !== ''" :href="item.url">
                          <span class="span1">{{item.title}}</span> <span class="span2">{{ dayjs(item.publishDate).format('MM-DD') }}</span>
                        </a>
                        <router-link v-else :to="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                          <span class="span1">{{item.title}}</span> <span class="span2">{{ dayjs(item.publishDate).format('MM-DD') }}</span>
                        </router-link>
                      </li>
                    </ul>
                    <!-- 媒体聚焦 -->
                    <ul class="comUl" v-show="catalogActive == 1">
                      <li v-for="(item, index) in newCatalogList02" :key="index">
                        <a v-if="item.url && item.url !== ''" :href="item.url">
                          <span class="span1">{{item.title}}</span> <span class="span2">{{ dayjs(item.publishDate).format('MM-DD') }}</span>
                        </a>
                        <router-link v-else :to="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                          <span class="span1">{{item.title}}</span> <span class="span2">{{ dayjs(item.publishDate).format('MM-DD') }}</span>
                        </router-link>
                      </li>
                    </ul>
                    <!-- 集团要闻 -->
                    <ul class="comUl" v-show="catalogActive == 2">
                      <li v-for="(item, index) in newCatalogList03" :key="index">
                        <a v-if="item.url && item.url !== ''" :href="item.url">
                          <span class="span1">{{item.title}}</span> <span class="span2">{{ dayjs(item.publishDate).format('MM-DD') }}</span>
                        </a>
                        <router-link v-else :to="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                          <span class="span1">{{item.title}}</span> <span class="span2">{{ dayjs(item.publishDate).format('MM-DD') }}</span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 重要公告部分 -->
        <div class="inform_box">
          <div class="layout">
            <p id="import" class="title">
              <a href="/list/0195" style="cursor:pointer;">
                <img src="../assets/image/tz.574e3c2.png" alt=""> 
                <span class="infoSpan" style="cursor:pointer;">重要通知 :</span> 
              </a>
              <el-carousel direction="vertical" height="36px" indicator-position="none" style="margin-left: 25px;margin-top:-36px;" class="importInfo">
                <el-carousel-item v-for="(item, index) in newsNoticeList" :key="index">
                  <a :href=" item.url && item.url !== '' ? item.url : `/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                    <span class="importTitle">{{ item.title }}</span>
                    <span class="time">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</span>
                  </a>
                </el-carousel-item>
              </el-carousel>
              <a href="/list/0195" class="importmore" style="cursor:pointer;">更多</a>
            </p>
          </div>
        </div>
        <div style="width:1200px;margin: 20px auto;">
          <a href="/listThird/16ff0381-218d-4dea-9422-eb5477251531" style="cursor:pointer;" target="_blank" >
            <img src="../assets/image/newChannel.jpg" alt=""  style="width: 100%"> 
          </a>
        </div>
        <!-- 业务板块 -->
        <div class="box">
          <div class="contentt">
            <div class="content-title">
              <img :src="advertSrc" alt="">
            </div> 
            <div class="content-nav">
              <ul class="contentMenuUl">
                <li :class="{ active: active == index }"
                  v-for="(item, index) in ywList"
                  :key="index"
                  class="contentMenuLi"
                  @mouseenter="controlImg(item, index)">
                  <a v-if="item.url && item.url !== ''" :href="item.url">{{ item.name }}</a>
                  <router-link v-else :to="`/list/${item.entTChannelUuid}`">{{item.name}}</router-link>
                </li>
              </ul>
              <div class="imgs">
                <el-carousel height="500px" ref="carousel" @change="carouselChange" :interval="5000">
                  <el-carousel-item v-for="(item, index) in ywList" :key="index" >
                    <a v-if="item.url && item.url !== ''" :href="item.url">
                      <img :src="item.fileName" style="width: 100% ; height: 100% ;" />
                    </a>
                    <a v-else :href="`/list/${item.entTChannelUuid}`">
                      <img :src="item.fileName" style="width: 100% ; height: 100% ;" />
                    </a>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
          <div id="leafContainer">
            <!-- <img src="../assets/img/hua2.gif" style="width: 220px; height: 350px;"> -->
            <div id="snowzone" style="width: 200px; height: 370px; overflow: hidden;position: relative;"></div>
          </div>
        </div>
        <!-- 项目展示 -->
        <div id="party_box" class="party_box">
          <div class="img">
            <div class="layout">
              <p class="title">
                <span>项目展示</span> 
                <ul class="partyUl">
                  <li 
                  :class="{ active: partyActive == index }"
                    v-for="(item, index) in partyList"
                    :key="index"
                    class="partyLi"
                    @click="controlParty(index)">
                    {{ item.name }}
                  </li>
                  <li class="more">
                    <a href="/list/01920001">更多</a>
                  </li>
                </ul>
              </p>
              <div class="lunbo_wrap" v-show="partyActive == 0">
                <div class="left">
                  <div class="up">
                    <div class="img">
                      <img :src="partyImg0" alt="">
                    </div>
                  </div>
                </div>
                <div id="partySelect" class="right">
                  <div class="right_list">
                    <ul class="lunbo_box">
                      <li v-for="(item, index) in JpgcList00" :key="index" 
                      @mouseenter="mouseenterPartyList0(item,index)"
                      :class="{ active: partyli0 == index }" 
                      >
                        <a v-if="item.url && item.url !== ''" :href="item.url">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                        <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                      </li>
                      <a href="/list/01920001" class="item_more"><i >&gt;&gt;</i></a>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="lunbo_wrap" v-show="partyActive == 1">
                <div class="left">
                  <div class="up">
                    <div class="img">
                      <img :src="partyImg1" alt="">
                    </div>
                  </div>
                </div>
                <div id="partySelect" class="right">
                  <div class="right_list">
                    <ul class="lunbo_box">
                      <li v-for="(item, index) in JpgcList01" :key="index" 
                      @mouseenter="mouseenterPartyList1(item,index)" 
                      :class="{ active: partyli1 == index }">
                        <a v-if="item.url && item.url !== ''" :href="item.url">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                        <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                      </li>
                      <a href="/list/01920002" class="item_more"><i >&gt;&gt;</i></a>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="lunbo_wrap" v-show="partyActive == 2">
                <div class="left">
                  <div class="up">
                    <div class="img">
                      <img :src="partyImg2" alt="">
                    </div>
                  </div>
                </div>
                <div id="partySelect" class="right">
                  <div class="right_list">
                    <ul class="lunbo_box">
                      <li v-for="(item, index) in JpgcList02" :key="index" 
                      @mouseenter="mouseenterPartyList2(item,index)" 
                      :class="{ active: partyli2 == index }">
                        <a v-if="item.url && item.url !== ''" :href="item.url">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                        <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                      </li>
                      <a href="/list/01920003" class="item_more"><i >&gt;&gt;</i></a>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="lunbo_wrap" v-show="partyActive == 3">
                <div class="left">
                  <div class="up">
                    <div class="img">
                      <img :src="partyImg3" alt="">
                    </div>
                  </div>
                </div>
                <div id="partySelect" class="right">
                  <div class="right_list">
                    <ul class="lunbo_box">
                      <li v-for="(item, index) in JpgcList03" :key="index" 
                      @mouseenter="mouseenterPartyList3(item,index)" 
                      :class="{ active: partyli3 == index }">
                        <a v-if="item.url && item.url !== ''" :href="item.url">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                        <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                      </li>
                      <a href="/list/01920004" class="item_more"><i >&gt;&gt;</i></a>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="lunbo_wrap" v-show="partyActive == 4">
                <div class="left">
                  <div class="up">
                    <div class="img">
                      <img :src="partyImg4" alt="">
                    </div>
                  </div>
                </div>
                <div id="partySelect" class="right">
                  <div class="right_list">
                    <ul class="lunbo_box">
                      <li v-for="(item, index) in JpgcList04" :key="index"
                       @mouseenter="mouseenterPartyList4(item,index)" 
                       :class="{ active: partyli4 == index }">
                        <a v-if="item.url && item.url !== ''" :href="item.url">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                        <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                      </li>
                      <a href="/list/01920005" class="item_more"><i >&gt;&gt;</i></a>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="lunbo_wrap" v-show="partyActive == 5">
                <div class="left">
                  <div class="up">
                    <div class="img">
                      <img :src="partyImg5" alt="">
                    </div>
                  </div>
                </div>
                <div id="partySelect" class="right">
                  <div class="right_list">
                    <ul class="lunbo_box">
                      <li v-for="(item, index) in JpgcList05" :key="index" 
                      @mouseenter="mouseenterPartyList5(item,index)" 
                      :class="{ active: partyli5 == index }">
                        <a v-if="item.url && item.url !== ''" :href="item.url">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                        <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                          <em class="lunbo_box_em"></em> 
                          <span class="lunbo_box_span">{{item.title}}</span>
                        </a>
                      </li>
                      <a href="/list/01920006" class="item_more"><i >&gt;&gt;</i></a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- 专题专栏 纪检举报 -->
        <div id="special_box" class="special_box">
          <div class="layout">
            <div class="flex">
              <div style="width: 390px; padding: 0 10px;">
                <p class="title">
                  <span>专题专栏</span> 
                  <a href="/special" class="more">更多</a>
                </p> 
                <div id="special" class="up">
                  <ul>
                    <li v-for="(item, index) in specialLIst" :key="index">
                      <a :href="`/listThird/${item.entTChannelUuid}`">
                        <img :src="item.fileName" alt="">
                      </a>
                    </li>
                  </ul>
                </div>
              </div> 
              <div class="down" style="width: 390px; padding: 0 10px;">
                <!-- <a href="/inform"> -->
                  <p class="title">
                    <span>纪检举报</span>
                  </p> 
                  <img src="../assets/image/jjjbzx.jpg" alt="" style="margin-top: 25px;">
                <!-- </a> -->
              </div>
              <div class="down" style="width: 390px; padding: 0 10px;">
                <a href="http://oa.cgico.cn:8088/" target="_blank">
                  <p class="title">
                    <span>协同办公</span>
                  </p> 
                  <img src="../assets/image/zggjszhzhxxglpt.jpg" alt="" style="margin-top: 25px;">
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- 中甘和我 -->
        <div class="bottom">
          <div class="business_box">
            <div class="layout">
              <p class="title">
                <span>中甘和我</span> 
                <a class="more" href="/listPicTwo/01830005">更多</a>
              </p> 
              <div class="firm">
                <a :href="firmList[0] && `/detail/${firmList[0].entTChannelUuid}/${firmList[0].entTArticleUuid}`">
                  <el-card class="box-card" shadow="never">
                    <div>
                      <img alt="" class="firm-img" :src="firmList[0] && firmList[0].thumb1">
                    </div>
                  </el-card>
                </a>
                <ul class="firm-content">
                  <li>
                    <a :href="firmList[1] && `/detail/${firmList[1] && firmList[1].entTChannelUuid}/${firmList[1].entTArticleUuid}`">
                      <el-card class="box-card" shadow="never">
                        <div>
                          <img :src="firmList[1] && firmList[1].thumb1">
                        </div>
                      </el-card>
                      <div class="title">
                        {{firmList[1] && firmList[1].artDesc}}
                      </div>
                    </a>
                  </li>
                  <li>
                    <a :href="firmList[2] && `/detail/${firmList[2].entTChannelUuid}/${firmList[2].entTArticleUuid}`">
                      <div class="title">
                        {{firmList[2] && firmList[2].artDesc}}
                      </div>
                      <el-card class="box-card" shadow="never">
                        <div>
                          <img :src="firmList[2] && firmList[2].thumb1">
                        </div>
                      </el-card>
                    </a>
                  </li>
                  <li>
                    <a :href="firmList[3] && `/detail/${firmList[3].entTChannelUuid}/${firmList[3].entTArticleUuid}`">
                      <el-card class="box-card" shadow="never">
                        <div>
                          <img :src="firmList[3] && firmList[3].thumb1">
                        </div>
                      </el-card>
                      <div class="title">
                        {{firmList[3] && firmList[3].artDesc}}
                      </div>
                    </a>
                  </li>
                  <li>
                    <a :href="firmList[4] && `/detail/${firmList[4].entTChannelUuid}/${firmList[4].entTArticleUuid}`">
                      <div class="title">
                        {{firmList[4] && firmList[4].artDesc}}
                      </div>
                      <el-card class="box-card" shadow="never">
                        <div>
                          <img :src="firmList[4] && firmList[4].thumb1">
                        </div>
                      </el-card>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { articles, getChildChannel, getAdvert, picArticles, picArticlesRecommend, getArtInfo } from '@/api/common'
export default {
  data() {
    return {
      partyImg0: '',
      partyImg1: '',
      partyImg2: '',
      partyImg3: '',
      partyImg4: '',
      partyImg5: '',
      active: 0,
      partyMenuActive: 0,
      partyli: '',
      partyli0: 0,
      partyli1: 0,
      partyli2: 0,
      partyli3: 0,
      partyli4: 0,
      partyli5: 0,
      ismouseover: true,
      indexImg: '',
      indexUrl: '',
      // 头条新闻轮播内容
      newsTextList: [],
      //轮播背景大图
      imgList: [],
      specialLIst: [],
      // imgList: [
      //   {fileName: require('../assets/image/cgicop0.png')},
      //   {fileName: require('../assets/image/cgicop1.png')},
      //   {fileName: require('../assets/image/cgicop2.png')},
      //   {fileName: require('../assets/image/cgicop3.jpg')},
      //   {fileName: require('../assets/image/cgicop4.jpg')},
      // ],
      yewuImg01: [],
      JpgcList00: [],
      JpgcList01: [],
      JpgcList02: [],
      JpgcList03: [],
      JpgcList04: [],
      JpgcList05: [],
      JpgcList2: [],
      //业务板块内容
      ywList: [],
      partyList: [],
      //精品工程轮播图
      JpgcList: [],
      //新闻列表轮播图
      flashImgList: [],
      //新闻目录
      catalogActive: 0,
      partyActive: 0,
      catalogList: [
        {
          id: 1,
          title: '公司新闻',
          url: '/list/01800001'
        },
        {
          id: 2,
          title: '媒体聚焦',
          url: '/list/01800002'
        },
        {
          id: 3,
          title: '集团要闻',
          url: '/list/01800003'
        }
      ],
      //新闻列表
      newtitleList: [],
      newCatalogList01: [],
      newCatalogList02: [],
      newCatalogList03: [],
      newsNoticeList: [],
      newsFlashList: [],
      firmList: [],
      advertSrc: ''
    }
  },
  filters: {
    //超过100位显示 ...
    ellipsis: function (value) {
      if (!value) return ''
      if (value.length > 120) {
        return value.slice(0, 120) + '...'
      }
      return value
    },
    filtersText(val) {
      // 要判断一下,如果是空就返回空字符串,不然会报错
      if (val != null && val != '') {
        var reg = /[\u4e00-\u9fa5]/g
        var names = val.match(reg)
        val = names.join('')
        return val.substr(0, 50) //获取富文本文字内容的前50个字符
      } else return ''
    }
  },
  computed: {},
  mounted() {
    var _this = this
    _this.bannerImg()
    _this.bannerNews()
    _this.newsNotice()
    _this.bannerJpgc()
    _this.cgicop()
    _this.picArticles()
    _this.newsCatalog()
    _this.businessInit()

    setInterval(() => {
      // var left = Math.random()*window.innerWidth;
      // var height = Math.random()*window.innerHeight;
      var left = Math.random() * document.getElementById('snowzone').offsetWidth
      var height = Math.random() * document.getElementById('snowzone').offsetHeight
      var src = require('../assets/img/snow' + Math.floor(Math.random() * 4 + 1) + '.png') //两张图片分别为"s1.png"、"s2.png"
      this.getSnow(left, height, src)
    }, 250)
  },
  methods: {
    // 雪花
    getSnow(left, height, src) {
      var div = document.createElement('div')
      var img = document.createElement('img')
      img.className = 'snow_img'
      div.appendChild(img)
      img.src = src
      div.style.position = 'absolute'
      div.style.left = left + 'px'
      div.style.height = height + 'px'
      document.getElementById('snowzone').appendChild(div)
      setTimeout(function () {
        if (document.getElementById('snowzone').contains(div)) {
          document.getElementById('snowzone').removeChild(div)
        }
      }, 5000)
    },
    controlImg(val, index) {
      this.active = index
      this.indexImg = val.fileName
      this.$refs.carousel.setActiveItem(index)
    },
    carouselChange(e) {
      this.active = e
    },
    // 业务板块
    businessInit() {
      var _this = this

      let data = {
        applicationUuid: _this.webId,
        parentUuid: '0182'
      }
      getChildChannel(data).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.ywList = data
        }
      })
      // 项目展示
      let data2 = {
        applicationUuid: _this.webId,
        parentUuid: '0192'
      }
      getChildChannel(data2).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.partyList = data.slice(0, 6)
        }
      })

      // 专题专栏
      let data3 = {
        applicationUuid: _this.webId,
        parentUuid: '0190'
      }
      getChildChannel(data3).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.specialLIst = data.slice(4, 5)
        }
      })
    },
    // 新闻中心焦点图
    picArticles() {
      var _this = this

      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01800001',
        pageNum: 1,
        pageSize: 5
      }
      picArticlesRecommend(data01).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsFlashList = data.rows
        }
      })
    },
    // 新闻动态-右
    newsCatalog() {
      var _this = this
      // 公司新闻
      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01800001',
        pageNum: 1,
        pageSize: 10
      }
      articles(data01).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newCatalogList01 = data.rows
        }
      })
      // 媒体聚焦
      let data02 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01800002',
        pageNum: 1,
        pageSize: 10
      }
      articles(data02).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newCatalogList02 = data.rows
        }
      })
      // 集团要闻
      let data03 = {
        // entTApplicationUuid: _this.webId,
        // channelUuid: '01800003',
        entTApplicationUuid: _this.webIdGroup,
        channelUuid: '00190001',
        pageNum: 1,
        pageSize: 10
      }
      articles(data03).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newCatalogList03 = data.rows
        }
      })
    },
    // 头部banner
    bannerImg() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        advertType: 1
      }
      getAdvert(data).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length &&
            data.forEach((line) => {
              if (line.fileName && line.fileName !== '') {
                _this.imgList.push(line)
              }
            })
        }
      })

      let data2 = {
        applicationUuid: _this.webId,
        advertType: 3
      }
      getAdvert(data2).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.advertSrc = data[0].fileName
        }
      })
    },
    //中甘头条
    bannerNews() {
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01800005',
        pageNum: 1,
        pageSize: 10
      }
      articles(data).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsTextList = data.rows
        }
      })
    },
    // 通知公告
    newsNotice() {
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: '0195',
        pageNum: 1,
        pageSize: 10
      }
      articles(data).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsNoticeList = data.rows
        }
      })
    },
    //项目展示
    bannerJpgc() {
      var _this = this

      let data1 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01920001',
        pageNum: 1,
        pageSize: 11
      }
      articles(data1).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.JpgcList00 = data.rows
          _this.partyImg0 = data.rows[0].thumb1
          setInterval(() => {
            if (_this.JpgcList00.length == _this.partyli0 + 1) {
              _this.partyli0 = 0
              _this.partyImg0 = data.rows[_this.partyli0].thumb1
            } else {
              _this.partyli0++
              _this.partyImg0 = data.rows[_this.partyli0].thumb1
            }
          }, 2000)
        }
      })
      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01920002',
        pageNum: 1,
        pageSize: 11
      }
      articles(data01).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.JpgcList01 = data.rows
          _this.partyImg1 = data.rows[0].thumb1
          setInterval(() => {
            if (_this.JpgcList01.length == _this.partyli1 + 1) {
              _this.partyli1 = 0
              _this.partyImg1 = data.rows[_this.partyli1].thumb1
            } else {
              _this.partyli1++
              _this.partyImg1 = data.rows[_this.partyli1].thumb1
            }
          }, 2000)
        }
      })
      let data02 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01920003',
        pageNum: 1,
        pageSize: 11
      }
      articles(data02).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.JpgcList02 = data.rows
          _this.partyImg2 = data.rows[0].thumb1
          setInterval(() => {
            if (_this.JpgcList02.length == _this.partyli2 + 1) {
              _this.partyli2 = 0
              _this.partyImg2 = data.rows[_this.partyli2].thumb1
            } else {
              _this.partyli2++
              _this.partyImg2 = data.rows[_this.partyli2].thumb1
            }
          }, 2000)
        }
      })
      let data03 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01920004',
        pageNum: 1,
        pageSize: 11
      }
      articles(data03).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.JpgcList03 = data.rows
          _this.partyImg3 = data.rows[0].thumb1
          setInterval(() => {
            if (_this.JpgcList03.length == _this.partyli3 + 1) {
              _this.partyli3 = 0
              _this.partyImg3 = data.rows[_this.partyli3].thumb1
            } else {
              _this.partyli3++
              _this.partyImg3 = data.rows[_this.partyli3].thumb1
            }
          }, 2000)
        }
      })
      let data04 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01920005',
        pageNum: 1,
        pageSize: 11
      }
      articles(data04).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.JpgcList04 = data.rows
          _this.partyImg4 = data.rows[0].thumb1
          setInterval(() => {
            if (_this.JpgcList04.length == _this.partyli4 + 1) {
              _this.partyli4 = 0
              _this.partyImg4 = data.rows[_this.partyli4].thumb1
            } else {
              _this.partyli4++
              _this.partyImg4 = data.rows[_this.partyli4].thumb1
            }
          }, 2000)
        }
      })
      let data05 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01920006',
        pageNum: 1,
        pageSize: 11
      }
      articles(data05).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.JpgcList05 = data.rows
          _this.partyImg5 = data.rows[0].thumb1
          setInterval(() => {
            if (_this.JpgcList05.length == _this.partyli5 + 1) {
              _this.partyli5 = 0
              _this.partyImg5 = data.rows[_this.partyli5].thumb1
            } else {
              _this.partyli5++
              _this.partyImg5 = data.rows[_this.partyli5].thumb1
            }
          }, 2000)
        }
      })
    },
    // 中甘和我
    cgicop() {
      var _this = this
      // 中甘和我
      let data06 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01830005',
        pageNum: 1,
        pageSize: 5
      }
      picArticles(data06).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.firmList = data.rows
          data.rows.length && _this.getArtInfo(data.rows[1].entTArticleUuid)
          // console.log(9999,data.rows[1].entTArticleUuid)
        }
      })
    },

    // 查询文章详情
    getArtInfo(params) {
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        entTArticleUuid: params
      }
      getArtInfo(data).then((res) => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.content1 = data.content
        }
      })
    },

    mouseenterCatalog(val) {
      var _this = this

      _this.catalogActive = val
    },
    controlParty(val) {
      var _this = this

      _this.partyActive = val
    },
    mouseenterPartyList0(val, index) {
      var _this = this
      _this.partyli0 = index
      _this.partyImg0 = val.thumb1
    },
    mouseenterPartyList1(val, index) {
      var _this = this
      _this.partyli1 = index
      _this.partyImg1 = val.thumb1
    },
    mouseenterPartyList2(val, index) {
      var _this = this
      _this.partyli2 = index
      _this.partyImg2 = val.thumb1
    },
    mouseenterPartyList3(val, index) {
      var _this = this
      _this.partyli3 = index
      _this.partyImg3 = val.thumb1
    },
    mouseenterPartyList4(val, index) {
      var _this = this
      _this.partyli4 = index
      _this.partyImg4 = val.thumb1
    },
    mouseenterPartyList5(val, index) {
      var _this = this
      _this.partyli5 = index
      _this.partyImg5 = val.thumb1
    }
  }
}
</script>

<style lang="scss">
.body {
  min-width: 1200px;
  overflow-y: auto;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.homePage {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.homePage .ziHead {
  min-width: 1200px;
  position: relative;
  z-index: 1;
  height: 570px;
  text-align: center;
}
.news_box {
  min-height: 706px;
  margin-bottom: 30px;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.news_box .news_top {
  min-height: 156px;
  min-width: 1200px;
  padding: 32px 0 28px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.news_box .news_top img {
  width: 163px;
  height: 154px;
}
.block {
  width: 1000px;
  text-align: center;
}
.el-carousel--horizontal {
  overflow-x: hidden;
}
.el-carousel--horizontal {
  overflow-x: hidden;
}
.news_box .news_top .con p {
  color: #000;
  font-size: 26px;
  line-height: 57px;
  height: 57px;
  cursor: pointer;
  -webkit-line-clamp: 1;
  font-family: Microsoft YaHei;
  font-weight: 700;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}
.news_box .news_top .con.conDesc {
  text-align: left !important;
}
.news_box .news_top .con .div,
.news_box .news_top .con p {
  // width: 950px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.news_box .news_top .con > div {
  height: 70px;
  padding-top: 5px;
  font-size: 16px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  color: #737373;
  text-indent: 2em;
  line-height: 35px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.news_box .news_list {
  height: 450px;
  overflow: hidden;
}
.news-title {
  width: 606px;
}
.news-title div {
  height: 44px;
  font-size: 20px;
  font-family: Lantinghei SC;
  font-weight: 700;
  color: #0162b3;
  line-height: 44px;
}
.news-title img {
  width: 606px;
  height: 12px;
  margin-bottom: 16px;
}
.news_box .news_list .img_box {
  float: left;
  width: 606px;
  height: 395px;
}
.news_box .news_list .img_box .image-w {
  height: 395px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.news_box .news_list .list {
  float: left;
  margin: -60px 0 0 25px;
  width: 560px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 7px;
}
.list {
  position: relative;
  height: 100%;
}
#NewsT {
  width: 100%;
  background-color: #f5f5f8;
  border-bottom: 1px solid #e6e6e6;
  text-align: center;
}
.NewsTInner {
  width: 186px;
  height: 30px;
  font-size: 16px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  float: left;
  color: #222;
  line-height: 30px;
  background-color: #f5f5f8;
  display: inline-block;
  border-bottom: 1px solid #ddd !important;
  cursor: pointer;
}
.NewsTInner.active a {
  color: #fff;
}
.NewsTInner.active {
  color: #fff;
  height: 30px;
  font-size: 16px;
  font-family: Lantinghei SC;
  font-weight: 600;
  line-height: 30px;
  background-color: #0162b3;
  display: inline-block;
}
// .NewsTInner2{
//   width: 78px!important;
//   float: right;
//   padding-right: 2px;
//   // color: #409eff;
//   font-size: 16px;
//   border-bottom: 1px solid #0162b3!important;
//   font-family: FZLanTingHei-EL-GBK;
//   font-weight: 400;
//   background-color: #f5f5f8;
//   color: #0162b3;
//   display: inline-block;
//   height: 30px;
//   line-height: 30px;
// }
.NewsTInner a {
  display: block;
}
#NewsItem {
  padding: 30px 47px 0 48px;
  overflow: hidden;
  width: 465px;
}
.news_box .news_list #NewsItem li {
  height: 37px;
  line-height: 37px;
  font-size: 16px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  color: #222;
}
.news_box .news_list #NewsItem li:first-child a {
  color: #0162b3 !important;
}
.news_box .news_list #NewsItem li a {
  color: #222;
}
.news_box .news_list #NewsItem li:hover {
  background-color: #d6d6d6;
}
.news_box .news_list #NewsItem .span1 {
  float: left;
  width: 386px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news_box .news_list #NewsItem .span2 {
  float: right;
}
#import {
  margin-top: -30px;
  margin-bottom: 20px;
  line-height: 36px;
}
p.title span {
  font-weight: 700;
}
p.title {
  width: 1200px;
  height: 44px;
  line-height: 44px;
  font-family: Lantinghei SC;
  border-bottom: 1px solid #ddd;
  color: #0162b3;
  font-size: 20px;
}
#import img {
  width: 28px;
  height: 23px;
  vertical-align: middle;
}
#import .infoSpan {
  font-size: 16px;
  font-family: Lantinghei SC;
  font-weight: 700;
  color: #ef7c00;
  margin-left: 10px;
}
#import .importInfo a {
  // width: 1010px;
  width: 935px;
  height: 36px;
  display: inline-block;
  margin-left: 147px;
}
#import .el-carousel__item {
  width: 1090px;
}
#import .importmore {
  text-align: right;
  position: relative;
  top: -36px;
  float: right;
  display: inline-block;
  width: 75px;
  height: 36px;
  font-weight: normal;
  font-size: 16px;
  color: rgb(1, 98, 179);
  cursor: pointer;
}
#import .importInfo a:hover .importTitle {
  color: #0162b3;
}
#import .importInfo a .importTitle,
#import .importInfo a .time {
  font-size: 16px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  color: #222;
  line-height: 36px;
}
#import .importInfo a .importTitle {
  width: 800px;
  height: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
#import .importInfo a .time {
  height: 12px;
  float: right;
}

#import .importInfo a .importTitle,
#import .importInfo a .time {
  font-size: 16px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  color: #222;
  line-height: 36px;
}
.box,
.contentt {
  position: relative;
}
.box {
  width: 100%;
  min-width: 1200px;
  min-height: 757px;
  background: url(../assets/image/fei.df7e5ab.png) no-repeat, url(../assets/image/background.8fa94f4.png);
  background-position: 95% 32px, 0 0;
  background-size: 288px 349px, 100% 100%;
  box-sizing: border-box;
}
.contentt {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  z-index: 10;
}
.content-title {
  height: 150px;
  font-size: 48px;
  font-family: Lantinghei SC;
  font-weight: 600;
  color: #0162b3;
  line-height: 150px;
}
.content-title img {
  width: 1197px;
  height: 47px;
  vertical-align: middle;
}
.contentMenuUl {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  background-color: #efefef;
  border-bottom: 1px solid #e6e6e6;
}
.contentMenuLi {
  flex: 1;
  height: 30px;
  font-size: 15px;
  font-family: Lantinghei SC;
  font-weight: 600;
  line-height: 30px;
  color: #0162b3;
  text-align: center;
  border-right: 1px solid #ccc9ce !important;
  border-bottom: 1px solid #ccc9ce !important;
  -webkit-line-clamp: 1;
  padding: 0 !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  vertical-align: middle;
}
.contentMenuLi.active {
  background-color: #0162b3;
}
.contentMenuLi.active a {
  color: #fff;
  font-weight: 500;
}
.contentMenuLi a {
  color: #0162b3;
}
.imgs,
.imgs img {
  min-height: 500px;
}

.imgs {
  width: 1180px;
  padding: 10px;
  background-color: #fff;
}
.imgs img {
  display: block;
  width: 100%;
  height: 500px;
}
#party_box,
#special_box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.party_box {
  padding: 50px 0 100px;
  background: url(../assets/image/party.b3ced80.png) no-repeat;
  background-position: top;
  background-size: 100% 100%;
  box-sizing: border-box;
}
.party_box > .img > .layout {
  position: relative;
  overflow: hidden;
}
p.title,
p.title span {
  font-weight: 700;
}
p.title {
  width: 1200px;
  height: 44px;
  line-height: 44px;
  font-family: Lantinghei SC;
  border-bottom: 1px solid #ddd;
  color: #0162b3;
  font-size: 20px;
}
p.title,
p.title span {
  font-weight: 700;
}
.party_box .partyUl {
  border-bottom: 1px solid #e6e6e6;
  width: 75%;
  height: 43px;
  text-align: center;
  float: right;
  margin-top: 1.5px;
  display: flex;
  justify-content: right;
}
.party_box .partyLi {
  flex: 1;
  text-align: center;
  margin: 0 !important;
  padding: 0 !important;
  height: 44px;
  width: 79px;
  font-size: 16px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  color: #0162b3;
  line-height: 44px;
  cursor: pointer;
}
.party_box .partyLi:hover {
  color: #000;
}
.party_box .partyLi.active {
  border-bottom: 3px solid #0162b3 !important;
}
.party_box .more {
  color: #0162b3;
  font-size: 16px;
  font-weight: 400;
  float: right;
  width: 50px !important;
  flex: 0 0 auto !important;
}
.party_box .more a {
  color: #0162b3;
}
.party_box .left {
  float: left;
  padding-top: 20px;
  width: 560px;
  min-height: 400px;
  margin-top: 30px;
}
.party_box .left .up {
  height: 380px;
  width: 550px;
}
.party_box .left .up .img {
  height: 100%;
  width: 100%;
  float: left;
}
.party_box .left .up .img img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
#partySelect {
  float: right;
  margin: 33px 0 0;
  width: 640px;
}
.party_box .right_list {
  padding: 12px 5px 0 0;
}
.lunbo_box {
  height: 400px;
  overflow: hidden;
}
#partySelect .right_list ul li {
  display: flex;
  width: calc(100% - 100px);
}
.party_box .right_list li {
  height: 36px;
  line-height: 36px;
  font-size: 15px;
  margin-left: 20px;
}
.party_box .right_list li.active .lunbo_box_em {
  background-color: #0162b3 !important;
  font-weight: 700;
}
.party_box .right_list li.active .lunbo_box_span {
  color: #0162b3;
  font-weight: 700;
}
.party_box .right_list li .lunbo_box_em {
  float: left;
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #bbb;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 8px;
  background-color: #bbb !important;
}
// .lunbo_box_em {
//     background-color: #bbb!important;
// }
.party_box .right_list li .lunbo_box_em {
  font-size: 16px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  color: #222;
}
.party_box .right_list li .lunbo_box_span {
  float: left;
  width: 480px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  color: #222;
}
.item_more {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: 8px;
  line-height: 30px;
  text-align: center;
  margin-top: -20px;
  font-size: 22px !important;
  color: #444;
  cursor: pointer;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
}
.item_more:hover {
  color: #0162b3;
  transform: scale(1.5);
}
#special_box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.special_box {
  background-color: #d3e6f7;
  padding-top: 20px;
}
.flex {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.special_box p.title {
  width: 100%;
  border-bottom: 1px solid #fff;
}
p.title,
p.title span {
  font-weight: 700;
}
p.title {
  height: 44px;
  line-height: 44px;
  font-family: Lantinghei SC;
  color: #0162b3;
  font-size: 20px;
}
.special_box .more {
  float: right;
  font-size: 16px !important;
  font-family: Lantinghei SC;
  font-weight: 200 !important;
  color: #aab1b6 !important;
}
.special_box .up ul {
  width: 380px;
  margin: 25px 0 36px;
  display: flex;
  justify-content: space-between;
}
#special ul li {
  width: 380px;
  height: 150px;
}
.special_box .up li {
  float: left;
  width: 250px;
  height: 90px;
}
.special_box .up li img {
  width: 100%;
  height: 100%;
}
.special_box .down {
  width: 390px;
  height: 170px;
  margin-bottom: 26px;
  cursor: pointer;
}
.special_box .down img {
  width: 100%;
  height: 150px;
}
.bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.business_box {
  margin-top: 25px;
}
.business_box p.title {
  margin-bottom: -1px;
}
p.title .more {
  float: right;
  font-weight: 400;
  font-size: 16px;
  color: #0162b3;
}
.firm {
  height: 400px;
  margin: 32px 0;
  display: flex;
}
.el-card {
  height: 100%;
  border: none;
  position: relative;
}
.el-card {
  border-radius: 4px;
  // border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
}
.el-card img {
  transition: all 1s;
  -webkit-transition: all 1s;
  cursor: pointer;
}
// .el-card img:hover{
//   transform: scale(1.2);
// }
.firm-img {
  width: 400px;
  height: 400px;
  object-fit: cover;
}
.firm-content {
  flex: 1;
  background: #f9f9f9;
  display: flex;
  overflow: hidden;
}
.firm-content li .el-card {
  height: 50%;
  border: none;
  position: relative;
}
.firm-content li img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.el-card img {
  transition: all 1s;
  -webkit-transition: all 1s;
  cursor: pointer;
}
.el-card__body,
.el-main {
  padding: 0px;
}
.firm-content li .title {
  width: 156px;
  height: 152px;
  margin: 24px 22px;
  font-size: 14px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  color: #474747;
  line-height: 26px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.firm a:hover .el-card img {
  transform: scale(1.2);
}
.firm-content li:hover .title {
  color: #0162b3;
}
#leafContainer {
  position: absolute;
  width: 200px;
  height: 365px;
  top: 370px;
  right: 70px;
  overflow: hidden;
}
.snow_img {
  animation: mymove 5s;
  height: 20px;
  width: 20px;
  position: absolute;
  opacity: 0;
}
@keyframes mymove {
  0% {
    bottom: 100%;
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
    opacity: 0;
    bottom: 0;
  }
}
</style>
