<template>
  <div class="home">
    <home-zh v-if="$i18n.locale == 'zh-CN'" ref="homeZh"></home-zh>
    <home-en v-if="$i18n.locale == 'en-US'" ref="homeEn"></home-en>
  </div>
</template>

<script>
import HomeZh from './homeZh.vue'
import HomeEn from './homeEn.vue'
export default {
  components: {
    HomeZh,
    HomeEn
},
  data() {
    return {
      partyImg0:'',
      partyImg1:'',
      partyImg2:'',
      partyImg3:'',
      partyImg4:'',
      partyImg5:'',
      active: 0,
      partyMenuActive: 0,
      partyli: '',
      partyli0: 0,
      partyli1: 0,
      partyli2: 0,
      partyli3: 0,
      partyli4: 0,
      partyli5: 0,
      ismouseover: true,
      indexImg: '',  
      indexUrl: '',
      // 头条新闻轮播内容
      newsTextList: [],
      //轮播背景大图
      imgList: [],
      specialLIst: [],
      // imgList: [
      //   {fileName: require('../assets/image/cgicop0.png')},
      //   {fileName: require('../assets/image/cgicop1.png')},
      //   {fileName: require('../assets/image/cgicop2.png')},
      //   {fileName: require('../assets/image/cgicop3.jpg')},
      //   {fileName: require('../assets/image/cgicop4.jpg')},
      // ],
      yewuImg01: [],
      JpgcList00: [],
      JpgcList01: [],
      JpgcList02: [],
      JpgcList03: [],
      JpgcList04: [],
      JpgcList05: [],
      JpgcList2: [],
      //业务板块内容
      ywList: [],
      partyList: [],
      //精品工程轮播图
      JpgcList: [],
      //新闻列表轮播图
      flashImgList: [],
      //新闻目录
      catalogActive: 0,
      partyActive: 0,
      catalogList: [
        {
          id: 1,
          title: '公司新闻'
        },
        {
          id: 2,
          title: '行业新闻'
        },
        {
          id: 3,
          title: '集团要闻'
        },
      ],
      //新闻列表
      newtitleList: [],
      newCatalogList01: [],
      newCatalogList02: [],
      newCatalogList03: [],
      newsNoticeList: [],
      newsFlashList: [],
      firmList:[],
    }
  },
  filters: {
    //超过100位显示 ...
    ellipsis: function(value) {
      if (!value) return '' 
      if (value.length > 120) {
        return value.slice(0, 120) + '...'
      }
      return value
    },
  },
  computed: {},
  mounted() {
    // var _this = this
    // _this.bannerImg()
    // _this.bannerNews()
    // _this.newsNotice()
    // _this.bannerJpgc()
    // _this.cgicop()
    // _this.picArticles()
    // _this.newsCatalog()
    // _this.businessInit()
    
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
</style>
